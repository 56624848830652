import Cookies from "js-cookie"
import "./age_verification.scss"

const initAgeVerification = () => {
  // po kliku na tlačidlo pre overenie veku sa nastaví cookie a znovu načíta stránka
  const verifyAgeBtn = document.getElementById("verify-age")

  if (verifyAgeBtn) {
    verifyAgeBtn.addEventListener("click", () => {
      Cookies.set("age_verified", "1", { expires: 60 })
      location.reload()
    })
  }
}

export default initAgeVerification
