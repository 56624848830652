import MmenuLight from "mmenu-light"

const initMobileMenu = () => {
  const menuEl = document.querySelector("#mobile-menu")
  if (menuEl !== null) {
    const menu = new MmenuLight(menuEl)
    menu.navigation()
    const drawer = menu.offcanvas()

    document
      .querySelector("a[href='#mobile-menu']")
      .addEventListener("click", (evt) => {
        evt.preventDefault()
        drawer.open()
      })

    // po kliku na odkaz sa vysunuté menu zavrie
    menuEl
      .querySelectorAll("a")
      .forEach((el) => el.addEventListener("click", () => drawer.close()))
  }
}

export default initMobileMenu
