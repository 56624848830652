import "./base.scss"
import initAgeVerification from "./age_verification/age_verification"
import initMobileMenu from "./mobile_menu/mobile_menu"

const onReady = (fn) => {
  if (document.readyState !== "loading") {
    fn()
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

onReady(() => {
  initAgeVerification()
  initMobileMenu()
})
